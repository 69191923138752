.footer {
    background: $blackcol2;

    .footer-top {
        padding: 4em 0;

        @media #{$ipad} {
            padding: 3em 0;
        }
    }

    .footer-bottom {
        border-top: 1px solid #2F2F2F;
        padding: 1.25em 0;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media #{$ipad} {
            flex-direction: column;
        }
    }

    a {
        font-size: 1.375em;
    }

    .footer-left {
        width: 40%;

        @media #{$ipad} {
            width: 100%;
            margin-bottom: 2em;
        }

        .img-wrapper {
            width: 80%;
        }
    }

    .footer-center {
        width: 30%;

        @media #{$ipad} {
            width: 100%;
        }

        .inner-pages {
            ul {
                li {
                    &:first-child {
                        margin-bottom: 2.1875em;
                    }
                }
            }
        }
    }

    .footer-right {
        width: 20%;
        text-align: right;

        @media #{$ipad} {
            width: 100%;
            text-align: left;
        }

        .social-wrpper {
            position: relative;
            top: auto;
            right: auto;
            transform: none;
            margin-bottom: 3.125em;

            ul {
                display: flex;

                @media #{$ipad} {
                    justify-content: flex-start;
                }

                li {
                    margin: 0 0.46875em 0 0;

                    a {
                        width: 2em;
                        height: 2em;

                        svg {
                            width: 1em;
                        }
                    }
                }
            }
        }
    }
}