$primarycol: #CB3345;
$secondarycol: #F7C3C4;
$teritary: #CFBADA;

$whitecol: #ffffff;
$blackcol: #000000;
$blackcol2: #231F20;

// other colors

$graycol: #C5C5C5;
$graycol2: #F3F3F3;
$yellowcol:#FDEEC5;
$redcolor: #F0454E;


$basefont: 'Futura',
sans-serif;
$otherfont: 'PlayFairDisplay',
sans-serif;
$arabicFont: 'Cairo';
$transition: all 0.3s ease-in-out;
$bx-shadow: 0px 4px 17px rgba(55, 72, 106, 0.1);


//  Form Color
$successcol : #198754;
$dangercol : #dc3545;


// Media Sizes
$tab: '(min-width:576px)';
$tabL: '(min-width:768px)';
$ipadL: '(min-width:992px)';
$ipadXL: '(min-width:1025px)';
$desktop: '(min-width:1200px)';

$ipadpro: '(max-width:1199.98px)';
$ipad: '(max-width:991.98px)';
$mob: '(max-width:575.98px)';

$space1: 0.5em;
$space2: 1em;
$space3: 1.5em;