.primary-col {
    color: $primarycol;
}

.secondary-col {
    color: $secondarycol;
}

.teritary-col {
    color: $teritary;
}

.white-col {
    color: $whitecol;
}

.black-col {
    color: $blackcol;
}

.white-bg {
    background: $whitecol;
}

.primary-bg {
    background: $primarycol !important;
}

.black-bg {
    background: $blackcol !important;
}

.redcolor-col {
    color: $redcolor;
}