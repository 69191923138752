html {
  scroll-behavior: smooth;

  @media #{$ipadpro} {
    overflow-x: hidden;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

body {
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;

  @media (max-width: 1400px) and (min-width: 1300px) and (min-height: 900px) {
    font-size: 11.485px;
  }

  @media #{$desktop} {
    font-size: 16px;
  }

  @media #{$ipadpro} {
    font-size: 14px !important;
  }

  // @media #{$mob} {
  //   font-size: 13px;
  // }

  &.no-home {
    .header {
      border-bottom: 1px solid rgba($color: $blackcol, $alpha: 0.2);
      position: absolute;
      width: 100%;
      top: 0;
    }
  }

  &.no-home {
    .footer {
      border-top: 1px solid $whitecol;
    }
  }

}

img,
svg {
  max-width: 100%;
  height: auto;
  display: block;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-position: outside;

  li {
    margin-bottom: 1em;
  }
}

a {
  text-decoration: none;
  color: $primarycol;
}

iframe {
  border: 0;
}

button {
  -webkit-appearance: button;
  appearance: button;
  border: none;

  &:focus {
    outline: none;
  }
}

textarea {
  resize: none;
}

picture {
  display: block;
  width: 100%;
  height: 100%;
}


.social-wrpper {
  position: absolute;
  right: 4.6875em;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 6;

  @media #{$ipad} {
    right: 2em;
    top: 45%;
  }

  ul {
    li {
      margin-bottom: 0.5em;

      a {
        background: $whitecol;
        display: flex;
        justify-content: center;
        padding: 0.558em;
        border-radius: 50%;
        transition: $transition;
        width: 1.875em;
        height: 1.875em;

        &:hover {
          background: $primarycol;
          transition: $transition;
        }
      }
    }
  }
}