.home-hero {
    overflow: hidden;
    position: relative;
    padding-top: 7.64em;
    height: 100vh;

    @media #{$mob} {
        padding-top: 0;
        height: 100%;

    }

    &__heroImage {
        position: relative;
        overflow: hidden;

        @media #{$ipad} {
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        &::before {
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.84) -22.86%, rgba(12, 13, 14, 0) 71.44%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            transform: matrix(1, 0, 0, -1, 0, 0);
        }

        .video-wrapper {

            height: 100vh;

            &::before {
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.84) -22.86%, rgba(12, 13, 14, 0) 71.44%);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                transform: matrix(1, 0, 0, -1, 0, 0);
                z-index: 1;
            }

            iframe {
                width: 100vw;
                height: 62.25vw;
                min-height: 100vh;
                min-width: 177.77vh;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 0;

                @media #{$ipad} {
                    min-width: 90vh;

                }

                @media #{$mob} {
                    width: 150vh;
                    top: 58%;
                }


            }
        }

        .banner-logo {
            position: absolute;
            bottom: 8em;
            z-index: 5;
            left: 50%;
            transform: translate(-45%, 0);
            width: 35%;

            @media #{$ipadpro} {
                bottom: 10em;
            }

            @media #{$ipad} {
                bottom: 2em;
            }

            @media #{$mob} {
                width: 60%;
            }
        }
    }

    &__heroWrapper {
        position: absolute;
        bottom: 5em;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 4.6875em 0 14.0625em;
        align-items: flex-end;
        z-index: 5;

        @media #{$ipadpro} {
            bottom: 3em;
            padding: 0 5em;
        }

        @media #{$ipad} {
            position: relative;
            bottom: 0;
            background: rgba($color: $blackcol, $alpha: 0.9);
            padding: 3em;
        }

        &--leftWrapper {
            max-width: 22%;

            @media #{$ipadpro} {
                max-width: 35%;
            }

            @media #{$ipad} {
                max-width: 100%;
            }
        }

        &--rightWrapper {}
    }
}