h1,
.h1 {
  font-size: 5em; // 80px
  font-weight: 700;
  margin: 0 0 0.35em 0;
  line-height: 1.2;
  letter-spacing: -0.01em;
  text-transform: capitalize;

  @media #{$ipadpro} {
    font-size: 3.5em;
  }

  @media #{ $ipad} {
    font-size: 2.875em;
    margin: 0 0 0.5em 0;
  }
}

h2,
.h2 {
  font-size: 3.75em; // 60px
  font-weight: 700;
  margin: 0 0 0.833em 0;
  line-height: 1.25;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: $blackcol2;

  @media #{$ipadpro} {
    font-size: 2em;
    margin-bottom: 1.1em;
  }

  @media #{$ipad} {
    font-size: 1.75em;
  }

  @media #{$mob} {
    margin-bottom: 0.7em;
  }
}

h3,
.h3 {
  font-size: 2.875em; // 46px
  font-weight: 700;
  margin: 0 0 .5em 0;
  line-height: 1.25;
  text-transform: capitalize;
  letter-spacing: -0.02em;

  @media #{$ipadpro} {
    font-size: 2em;
  }

  @media #{ $ipad} {
    font-size: 1.5em;
  }
}

h4,
.h4 {
  font-size: 2.25em; // 36px
  font-weight: 500;
  margin: 0 0 0.6em 0;
  line-height: 1;
  text-transform: capitalize;

  &.sm-font {
    font-size: 2.125em; // 34px

    @media #{$ipadpro} {
      font-size: 1.5em;
    }

    @media #{$mob} {
      font-size: 1.25em;
    }
  }

  @media #{$ipadpro} {
    font-size: 1.5em;
  }

  @media #{$mob} {
    font-size: 1.25em;
  }
}

h5,
.h5 {
  font-size: 1.875em; // 30px
  font-weight: 400;
  margin: 0 0 0.6818em 0;
  line-height: 1.5;

  @media #{ $ipad} {
    font-size: 1.125em;
  }

  @media #{$mob} {
    font-size: 1em;
  }
}

h6,
.h6 {
  font-size: 1.25em; // 20px
  font-weight: 700;
  margin: 0 0 0.75em 0;
  line-height: 1.3em;
  text-transform: inherit;
  letter-spacing: -0.02em;

  @media #{$ipadpro} {
    font-size: 1em;
  }

  &.xl-font {
    font-size: 1.375em; // 22px
    font-family: $otherfont;
    font-weight: 400;

    @media #{$ipadpro} {
      font-size: 1em;
    }
  }

  &.xxl-font {
    font-size: 1.5em; // 24px
    font-family: $otherfont;
    font-weight: 400;

    @media #{$ipadpro} {
      font-size: 1em;
    }
  }
}

p,
.p {
  font-size: 1.25em; // 20px
  font-weight: 400;
  margin: 0 0 1.578em 0;
  line-height: 1.3684em;
  letter-spacing: -0.02em;

  &.xl-font {
    font-size: 1.375em; // 22px
  }

  &.sm-font {
    font-size: 1.125em; // 18px
  }

  &.xs-font {
    font-size: 1em; // 16px
  }

  @media #{$ipadpro} {
    font-size: 1em;
  }

  @media #{$ipad} {
    font-size: 1em;
  }
}