// Futura Regular
@font-face {
    font-family: "Futura";
    src: url("/assets/fonts/Futura.otf");
    font-weight: 400;
}

// Futura Bold
@font-face {
    font-family: "Futura";
    src: url("/assets/fonts/Futura-Bold.otf");
    font-weight: 700;
}

// Futura LT Heavy
@font-face {
    font-family: "Futura";
    src: url("/assets/fonts/FuturaLT-Heavy.otf");
    font-weight: 800;
}

// Futura PlayFairDisplay
@font-face {
    font-family: "PlayFairDisplay";
    src: url("/assets/fonts/PlayfairDisplay-Regular.otf");
    font-weight: 400;
}

// Arabic font
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;800;900;1000&display=swap');