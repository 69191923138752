.header {
    z-index: 99;
    position: absolute;
    width: 100%;

    &__wrapper {
        padding: 2.375em 0;
    }

    &.sticky {
        position: fixed !important;
        top: 0;
        width: 100%;
        background: $whitecol;
        z-index: 999;
    }
}