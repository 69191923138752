.navigation-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__rightWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .menu {
      &__menuitem {
        @media #{$ipad} {
          border: none;
          text-align: right;
          margin: 0;
          margin-right: 2em;
        }

        &--menulink {
          font-family: $arabicFont;

          @media #{$ipad} {
            color: $blackcol;
          }
        }
      }
    }

    .anchor-wrapper {
      @media #{$ipad} {
        display: none;
      }
    }
  }
}

.navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media #{$ipadpro} {
    padding-left: 1em;
  }

  @media #{$ipad} {
    position: fixed;
    right: -100%;
    width: 50%;
    height: 100vh;
    top: 0;
    transition: all 0.9s ease;
    background: $blackcol;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 7;
    overflow-y: scroll;
    padding: 0;
    box-shadow: 0 0 27px 2px rgba(0, 0, 0, .5) inset, 0 6px 4px -5px rgba(0, 0, 0, .5);

    &.open {
      right: 0;
    }
  }

  @media #{$mob} {
    width: 100%;
  }


}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
  list-style-type: none;
  padding: 0 0 0 0;
  margin: 0 -1.5625em;

  @media #{$ipadpro} {
    // margin: 0 1em;
  }

  @media #{$ipad} {
    padding: 6em 2em 2em 2em;
    margin: 0;
    flex-direction: column;
    width: 100%;
  }

  &__menuitem {
    position: relative;
    padding: 0;
    margin: 0 1.5625em;

    @media #{$ipadpro} {
      margin: 0 0.5em;
    }

    @media #{$ipad} {
      width: 100%;
      padding: 0.75em 0 0.75em 0;
      border-bottom: 1px solid $whitecol;
      margin: 0;
      margin-bottom: 1em;
    }

    &--menulink {
      display: inline-block;
      font-size: 1.0625em;
      font-weight: 500;
      font-family: $basefont #{'/*rtl: Cairo */'};
      line-height: 1;
      color: $blackcol;
      text-transform: uppercase;
      text-decoration: none;
      transition: $transition;
      letter-spacing: 0.03em;

      @media #{$ipadpro} {
        font-size: 0.85em;
      }

      @media #{$ipad} {
        color: $whitecol;
        font-size: 1em;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 111%;
        width: 100%;
        height: 2px;
        border-radius: 2.3125em;
        background: $primarycol;
        transform: scaleX(0);
        transition: $transition;
      }

      &:hover,
      &.active {
        color: $primarycol;

        &::before {
          transform: scaleX(1);

          @media #{$ipad} {
            transform: scale(0);
          }
        }
      }
    }
  }
}

// navigation
.nav-button {
  display: none;
  position: relative;
  height: 0.875em;
  width: 1.9375em;
  padding: 0;
  background: transparent;
  transition: all 250ms ease-out;
  z-index: 99;
  cursor: pointer;

  @media #{$ipad} {
    display: flex;
  }

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background: $primarycol;
    transition: all 250ms ease-out;
    will-change: transform;
  }

  &::before {
    transform: translateY(0);
  }

  &::after {
    transform: translateY(10px);
  }

  &.active {
    &::before {
      transform: translateY(0) rotate(45deg);
    }

    &::after {
      transform: translateY(0) rotate(-45deg);
    }
  }
}