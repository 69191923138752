.anchor-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 5;
}

.anchor-arrow {
  display: inline-flex;
  align-items: center;
  transition: 0.5s;
  border-radius: 2em;
  background: $primarycol;
  color: $whitecol;
  padding: 0.613em 2em;
  position: relative;
  font-size: inherit;
  font-family: inherit;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $secondarycol;
    left: 0;
    z-index: -1;
    bottom: 0;
    border-radius: 2em;
    transition: $transition;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    &::before {
      bottom: -0.5em;
      transition: $transition;
      opacity: 1;
      visibility: visible;
    }
  }

  &.gray-bg {
    background: #c9c9c9;

    &::before {
      background: rgba($color: #c9c9c9, $alpha: 0.4);
    }
  }

  &.black-col {
    background: $blackcol;
  }
}

.uline {
  position: relative;
  text-decoration: none;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.125em;
    width: 100%;
    height: 1px;
    background: $secondarycol;
    transform: scaleX(1);
    transition: transform 0.5s ease;
  }

  &:hover,
  &.active {
    &::after {
      transition: transform 0.5s ease;
      transform: scaleX(0);
    }
  }
}

.w-uline {
  position: relative;
  text-decoration: none;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.19em;
    width: 100%;
    height: 2px;
    background: $whitecol;
    transform: scaleX(0);
    transition: $transition;
  }

  &:hover,
  &.active {
    color: $whitecol;

    &::after {
      transition: $transition;
      transform: scaleX(1);
    }
  }
}