.faqs {
  &__faq-accordion {
    .open-icon {
      width: 1.29em;
      height: 1.29em;
      position: relative;
      transition: 0.5s;

      &::before,
      &:after {
        content: '';
        position: absolute;
        background-color: $blackcol;
        left: 0;
        top: 50%;
        width: 2px;
        height: 0.8125em;
        transition: all 0.5s ease-in-out;
      }

      &::before {
        top: 0.4em;
        left: 0.6em;
        height: 0.5em;
      }

      &::after {
        left: 0.37em;
        width: 0.55em;
        height: 2px;
        transform: translateY(-50%);
      }
    }

    .h5 {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border-top: 1px solid $graycol;
      padding-top: 1em;

      &.opened {
        .open-icon {
          &::before {
            opacity: 0;
            visibility: hidden;
          }

          &:after {
            left: 0.37em;
            width: 0.55em;
            height: 2px;
            transform: translateY(-50%);
          }
        }
      }
    }

    .accordion-wrap {
      max-height: 0;
      overflow: hidden;
      transition: 0.5s;

      .p {
        @media #{$mob} {
          font-size: 0.875em;
        }
      }

      ul {
        list-style: inside;
        padding-left: 1em;

        li {
          font-size: 1.25em;

          @media #{$ipadpro} {
            font-size: 1em;
          }
        }
      }
    }
  }

  &__leftWrapper {
    border-bottom: 1px solid $graycol;
  }

  .anchor-wrapper {
    margin-top: 6.25em;

    @media #{$mob} {
      margin-top: 2em;
    }
  }

  &__rightWrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: initial #{'/*rtl:row-reverse */'};

    @media #{$desktop} {
      padding-left: 2em;
    }

    .img-wrapper {
      &:nth-child(1) {
        @media #{$desktop} {
          margin-bottom: -0.6875em;
        }
      }
    }
  }
}

.join-sephora {
  background: $graycol2;

  &__img-wrapper {
    width: 30%;

    @media #{$mob} {
      width: 100%;
    }
  }

  .container {
    @media #{$desktop} {
      padding: 0;
    }
  }

  &__imageSlider {
    width: 100%;
    overflow: hidden;
    margin: 2.625em 0;

    .swiper-wrapper {
      -webkit-transition-timing-function: linear !important;
      -o-transition-timing-function: linear !important;
      transition-timing-function: linear !important;
    }

    .swiper-slide {
      padding: 0 0.875em;
      width: 13em;
    }

    .img-wrapper {
      border-radius: 1em;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }

  .bottom-text {
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30%;

    @media #{$ipad} {
      max-width: 100%;
    }
  }
}

.key-dates {
  background: $blackcol2;

  &__mainWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__leftImage {
    width: 26.21%;

    @media #{$mob} {
      display: none;
    }
  }

  &__rightImage {
    width: 28%;

    @media #{$mob} {
      display: none;
    }

    .img-wrapper {
      &:first-child {
        margin-bottom: 11.75em;
      }
    }
  }

  &__main-dates {
    width: 45%;

    @media #{$mob} {
      width: 100%;
    }

    .h2 {
      margin-bottom: 1.21em;
    }

    .individual-date {
      text-align: center;
      position: relative;
      padding-bottom: 4.125em;

      &:last-child {
        padding-bottom: 2.75em;
      }

      &::before {
        content: '';
        position: absolute;
        background: url('../svg/arrow-down.svg') no-repeat;
        width: 1.25em;
        height: 1em;
        bottom: 1.25em;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      .h3 {
        color: $yellowcol;
      }

      .p {
        color: $whitecol;
        margin: 0;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

.beauty-influencer {
  .container {
    @media #{$ipadL} {
      padding-right: 0;
    }
  }

  .h2 {
    text-transform: inherit;
  }

  &__mainWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__leftWrapper {
    width: 50%;

    @media #{$ipadpro} {
      width: 40%;
    }

    @media #{$ipad} {
      width: 100%;
    }

    .img-wrapper {
      @media #{$ipad} {
        margin-bottom: 2em;
      }
    }
  }

  &__rightWrapper {
    width: 40%;
    background: url('../images/influencer-right.webp') no-repeat;
    background-size: cover;
    min-height: 70em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5.1875em;

    @media #{$ipadpro} {
      width: 50%;
    }

    @media #{$ipad} {
      width: 100%;
      min-height: 100%;
      padding-bottom: 3em;
    }

    .p {
      @media #{$ipadpro} {
        padding: 0 1em;
      }
    }

    .img-wrapper {
      width: 22.625em;
      height: 31.875em;
    }

    .heading-wrapper {
      padding-top: 2em;
    }
  }

  &__bottom-tagSlider {
    width: 100%;
    overflow: hidden;
    cursor: default !important;

    .swiper-wrapper {
      -webkit-transition-timing-function: linear !important;
      -o-transition-timing-function: linear !important;
      transition-timing-function: linear !important;
    }

    .swiper-slide {
      width: auto;
      // height: 2.68em;
    }

    .separate-tag {
      padding: 1.0625em 0.75em;

      .h6 {
        margin: 0;
        font-weight: 700;
      }
    }

    .one {
      background: #cb3345;
      color: #ffd4d5;
    }

    .two {
      background: #f7c3c4;
      color: #861719;
    }

    .three {
      background: #fdeec5;
      color: #e27125;
    }

    .four {
      background: #ffc844;
      color: #dd7a3b;
    }

    .five {
      background: #dd7a3b;
      color: $whitecol;
    }

    .six {
      background: #144a56;
      color: $whitecol;
    }

    .seven {
      background: #39a49f;
      color: $whitecol;
    }

    .eight {
      background: #afdfe4;
      color: #144a56;
    }

    .nine {
      background: #c7dff2;
      color: #0e4c87;
    }
  }
}

.personalized-perks {
  background: $blackcol2;

  .container {
    @media #{$desktop} {
      padding-right: 0;
    }
  }

  .custom-row {
    align-items: center;
  }

  &__leftWrapper {
    @media #{$desktop} {
      padding-right: 12em;
    }

    @media #{$ipad} {
      padding-bottom: 2em;
    }
  }

  &__eventSlider {
    overflow: hidden;
    cursor: url('../svg/drag-cursor.svg'), auto;
    position: relative;

    .swiper-slide {
      @media #{$desktop} {
        transform: scale(0.75);
        transition: 0.5s;
        -webkit-filter: grayscale(100%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }

      &.swiper-slide-active {
        @media #{$desktop} {
          transform: scale(1);
          transition: 0.5s;
          -webkit-filter: grayscale(0);
          /* Safari 6.0 - 9.0 */
          filter: grayscale(0);
        }
      }
    }

    .swiper-scrollbar {
      height: 6px;
      background: rgba($color: $whitecol, $alpha: 0.15);
      width: 98% #{'/*rtl: 95% */'};

      @media #{$ipadpro} {
        width: 100%;
      }
    }

    .swiper-scrollbar-drag {
      background: $whitecol;
    }
  }

  .event-card {
    .img-wrapper {
      border-radius: 1em;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .content-wrapper {
      padding: 2em 0 2em 0;

      .h3 {
        text-transform: inherit;
      }
    }
  }
}

.how-works {
  &__main-wrapper {
    display: flex;
    height: 53em #{'/*rtl: 58em */'};

    @media #{$ipadpro} {
      height: 100%;
      flex-direction: column;
    }
  }

  &__leftWrapper {
    @media #{$desktop} {
      width: 50%;
    }
  }

  &__rightWrapper {
    z-index: -1;

    @media #{$desktop} {
      width: 50%;
    }

    @media #{$ipadpro} {
      margin-top: -6em;
    }

    @media #{$ipad} {
      margin-top: 18em;
    }

    .img-wrapper {
      width: 50em;
      margin-left: auto;
      height: 100%;
      overflow: hidden;
      border-top-left-radius: 0;
      border-top-right-radius: 2em;
      border-bottom-right-radius: 2em;
      border-bottom-left-radius: 0;

      @media #{$ipadpro} {
        width: 100%;
        border-radius: 2em;
      }

      @media #{$mob} {
        height: 23em;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;

        @media #{$mob} {
          width: 100%;
        }
      }
    }
  }

  &__cardSlider {
    height: 100%;
    position: relative;

    @media #{$ipadpro} {
      margin-bottom: 5em;
      height: 50em;
    }

    @media (max-width: 960px) and (min-width: 768px) {
      height: 41em !important;
    }

    @media #{$ipad} {
      margin-bottom: 3em;
      height: 35em;
    }

    @media (max-width: 768px) {
      height: 41em;
    }

    @media #{$mob} {
      height: 39em #{'/*rtl: 43em */'};
    }

    .individual-card {
      background: $blackcol2;
      padding: 3.625em 1.875em 2.625em 2.125em;
      border-radius: 2.1875em;
      width: 38.851em;
      position: absolute;
      left: 0;
      height: 100%;
      cursor: pointer;

      @media #{$ipad} {
        width: 100%;
        left: 0 !important;
      }

      @media #{$mob} {
        padding: 2em;
      }

      .inner-card {
        min-height: 42.5em #{'/*rtl: 48em */'};

        @media #{$ipadpro} {
          min-height: 39em;
        }

        @media #{$ipad} {
          min-height: 31em #{'/*rtl: 36em */'};
        }
      }

      .p,
      .h3,
      .list,
      .anchor-wrapper {
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
      }

      .p,
      .h3,
      li,
      .number span {
        color: $whitecol;
      }

      .h3 {
        margin-bottom: 1.57em;
      }

      .number {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;

        span {
          font-size: 6.25em;
          display: flex;
          font-weight: 700;

          @media #{$mob} {
            font-size: 4em;
          }
        }

        @media #{$ipad} {
          margin-top: 1em;
        }

        @media #{$mob} {
          margin-top: 0;
        }
      }

      .list {
        max-width: 86%;

        ul {
          li {
            position: relative;
            font-size: 1.375em;
            padding-left: 1.5em;
            line-height: 1.875;

            @media #{$mob} {
              font-size: 1em;
            }

            &::before {
              content: '';
              position: absolute;
              background: url('../svg/arrow-right.svg') no-repeat;
              width: 0.875em;
              height: 0.8em;
              left: 0;
              top: 0.55em #{'/*rtl:0.65em */'};
              background-size: contain;
              transform: none #{'/*rtl:rotate(60deg) */'};

              @media (max-width: 1400px) and (min-width: 1200px) {
                top: 0.5em #{'/*rtl:0.65em */'};
              }
            }
          }
        }

        ol {
          list-style: disc;
          padding-left: 1.5em;

          li {
            position: relative;
            font-size: 1.375em;

            @media #{$mob} {
              font-size: 1em;
            }
          }
        }
      }

      &.active {
        z-index: 3 !important;

        .p,
        .h3,
        .list,
        .anchor-wrapper {
          opacity: 1;
          visibility: visible;
          transition: 0.5s;
        }
      }

      &.viewed {
        .number {
          justify-content: flex-start;

          @media #{$ipad} {
            position: absolute;
            top: 1.875em;
            right: 1.875em;
          }

          @media #{$mob} {
            top: 0;
            margin-top: 3em;
          }
        }
      }
    }

    .individual-parent {
      width: auto;

      @media #{$ipadpro} {
        padding: 0 1em;
      }

      @media #{$mob} {
        padding: 0;
      }

      &:nth-child(1) {
        .individual-card {
          z-index: 3;
        }
      }

      &:nth-child(2) {
        .individual-card {
          left: 7.5em;
          background: $primarycol;
          z-index: 2;

          .number {
            span {
              color: #ffd4d5;
            }
          }

          @media #{$ipad} {
            margin-top: 8em;
          }
        }
      }

      &:nth-child(3) {
        .individual-card {
          left: 15em;
          background: $yellowcol;
          z-index: 1;

          .number {
            span {
              color: #e27125;
            }
          }

          &.active {
            background: $blackcol;
          }

          @media #{$ipad} {
            margin-top: 15.65em;
          }
        }
      }

      &:nth-child(4) {
        .individual-card {
          left: 22em;
          background: #39a49f;
          z-index: 0;

          .number {
            span {
              color: #f1f2ca;
            }
          }

          @media #{$ipad} {
            margin-top: 23em;
          }
        }
      }
    }
  }
}