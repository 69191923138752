.overflow-hidden {
    @media #{$ipad} {
        overflow: hidden;
    }
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.m-auto {
    margin: 0 auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: $space1 !important;
}

.p-2 {
    padding: $space2 !important;
}

.p-3 {
    padding: $space3 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.px-1 {
    padding-left: $space1 !important;
    padding-right: $space1 !important;
}

.px-2 {
    padding-left: $space2 !important;
    padding-right: $space2 !important;
}

.px-3 {
    padding-left: $space3 !important;
    padding-right: $space3 !important;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: $space1 !important;
    padding-bottom: $space1 !important;
}

.py-2 {
    padding-top: $space2 !important;
    padding-bottom: $space2 !important;
}

.py-3 {
    padding-top: $space3 !important;
    padding-bottom: $space3 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: $space1 !important;
}

.pt-2 {
    padding-top: $space2 !important;
}

.pt-3 {
    padding-top: $space3 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: $space1 !important;
}

.pb-2 {
    padding-bottom: $space2 !important;
}

.pb-3 {
    padding-bottom: $space3 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: $space1 !important;
}

.pl-2 {
    padding-left: $space2 !important;
}

.pl-3 {
    padding-left: $space3 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: $space1 !important;
}

.pr-2 {
    padding-right: $space2 !important;
}

.pr-3 {
    padding-right: $space3 !important;
}


.m-0 {
    margin: 0;
}

.m-1 {
    margin: $space1 !important;
}

.m-2 {
    margin: $space2 !important;
}

.m-3 {
    margin: $space3 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mx-1 {
    margin-left: $space1 !important;
    margin-right: $space1 !important;
}

.mx-2 {
    margin-left: $space2 !important;
    margin-right: $space2 !important;
}

.mx-3 {
    margin-left: $space3 !important;
    margin-right: $space3 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: $space1 !important;
    margin-bottom: $space1 !important;
}

.my-2 {
    margin-top: $space2 !important;
    margin-bottom: $space2 !important;
}

.my-3 {
    margin-top: $space3 !important;
    ;
    margin-bottom: $space3 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: $space1 !important;
}

.mt-2 {
    margin-top: $space2 !important;
}

.mt-3 {
    margin-top: $space3 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: $space1 !important;
}

.mb-2 {
    margin-bottom: $space2 !important;
}

.mb-3 {
    margin-bottom: $space3 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: $space1 !important;
}

.ml-2 {
    margin-left: $space2 !important;
}

.ml-3 {
    margin-left: $space3 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: $space1 !important;
}

.mr-2 {
    margin-right: $space2 !important;
}

.mr-3 {
    margin-right: $space3 !important;
}

.text-lower {
    text-transform: lowercase !important;
}

.text-upper {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.generic--radius {
    border-radius: 10px;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.align-item-center {
    align-items: center;
}

.align-self-center {
    align-self: center;
}

.text-center {
    text-align: center;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.mobile-only {
    @media #{$tab} {
        display: none;
    }
}


.desktop-only {
    @media #{$mob} {
        display: none;
    }
}



.img-hack {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-hack-contain {
    @extend .img-hack;
    object-fit: contain;
}

.border-radius-10 {
    border-radius: 0.625em;
    overflow: hidden;
}

.container-right-zero {
    @media #{$desktop} {
        padding-right: 0;
    }
}

.container-right-zero-always {
    padding-right: 0;

    @media #{$mob} {
        padding-right: 2em;
    }
}

.other-font {
    font-family: $otherfont;
}


// line clamps
.line-clamp-1 {
    @include clamp;
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    @include clamp;
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    @include clamp;
    -webkit-line-clamp: 3;
}

.line-clamp-4 {
    @include clamp;
    -webkit-line-clamp: 4;
}


// font weights
$fonts-w: ("300": 300,
    "400": 400,
    "500": 500,
    "600": 600,
    "700": 700,
    "800": 800,
);

@each $fontname,
$fontvalue in $fonts-w {
    .fw-#{$fontname} {
        font-weight: $fontvalue;
    }
}